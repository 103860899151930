@import '~bootstrap/scss/bootstrap';

//
// Color system
//
$blue:                        #0087d3;
$primary:                     $blue;
$theme-colors:                ();
// Options
//
// Quickly modify global styling by enabling or disabling optional features.
$enable-rounded: false;

// Body
//
// Settings for the `<body>` element.
$body-bg:                     $white;
$body-color:                  $gray-800;

// Links
// Style anchor elements.
//
// Links
//
// Style anchor elements.

$link-color:                  #3498db;
$link-decoration:             none;
$link-hover-color:            darken($link-color, 15%);
$link-hover-decoration:       underline;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif:      'Nunito Sans', Roboto, 'Helvetica Neue', Arial, sans-serif;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
$font-family-base:            $font-family-sans-serif;
// stylelint-enable value-keyword-case

$font-size-base:              0.875rem; // Assumes the browser default, typically `16px`
$font-size-lg:                $font-size-base * 1.25;
$font-size-sm:                $font-size-base * .875;

// Forms
$input-btn-font-size:         $font-size-base;
$btn-font-size:               $input-btn-font-size;
$input-font-size:             $input-btn-font-size;

$custom-select-font-size: $font-size-base;

// Breadcrumbs
$breadcrumb-bg:               #fff;


@import "~bootstrap/scss/functions"; // Required
@import "~bootstrap/scss/variables"; // Required
@import "~bootstrap/scss/mixins"; // Required

@import "~bootstrap/scss/root"; // Required
@import "~bootstrap/scss/reboot"; // Required
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group"; // Requires forms
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar"; // Requires nav
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal"; // Requires transitions
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";
