// default font
@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:200,300,400,400i,600,700,900');

// sprite flags
@import url('https://img.iafcertsearch.org/r/flags/24/flags.min.css');

// font-awesome
// $fa-font-path: '~font-awesome/fonts';
$fa-font-path: 'https://f0.iafcertsearch.org/assets/libs/font-awesome/fonts';
@import '~font-awesome/scss/font-awesome';

// bootstrap
@import './custom';
//@import './variables';
//@import '~bootstrap/scss/bootstrap';

@import './react-select';
@import './toast';
@import './react-verification-input';
@import './steppers';
@import './progress';

// Hide amChat watermark
[aria-labelledby='id-79-title'] {
  display: none !important;
}

// global styles
:global(.text-ellipsis) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

:global(.white-space-pre) {
  white-space: pre;
}

:global(.font-size-sm) {
  font-size: $font-size-sm;
}

:global(.font-size-md) {
  font-size: $font-size-base;
}

:global(.text-del) {
  text-decoration: line-through;
}

:global(.cursor-pointer) {
  cursor: pointer;
}

:global(.cursor-default) {
  cursor: default;
}

label {
  font-weight: 600;
}

// Google maps auto complete container.
.pac-container {
  z-index: 2000;
}

// Make the whole calendar field clickable
input[type='date']::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

// temporary solution to remove border from footer
// the proper way is adding a customClass: {footer :"class"}
// but the installed version of swal2 doesn't support this
// needs atleast 8.17.6 version of swal2
:global(.swal2-footer) {
  border: none !important;
}
